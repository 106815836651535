<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-checkbox-marked-outline</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Aufgaben</v-toolbar-title>
      <v-spacer />
      <v-text-field
        class="mr-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        single-line
        hide-details
        clearable
        id="search"
      ></v-text-field>
    </v-app-bar>
    <v-toolbar flat class="mb-4">
      <v-select
        v-model="selectedCategory"
        :items="categories"
        item-value="id"
        item-text="description"
        clearable
        single-line
        hide-details=""
        label="Kategorien"
      ></v-select>
    </v-toolbar>
    <v-card class="mt-2">
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="filtered"
        :sort-by="'description'"
        :items-per-page="15"
      >
        <template v-slot:item.complete="{ item }">
          <v-simple-checkbox
            :disabled="item.complete"
            @input="complete(item)"
            :value="item.complete"
          />
        </template>
        <template v-slot:item.person="{ item }">
          <PersonItem v-if="item.person" :value="item.person" clickable />
        </template>
        <template v-slot:item.automatic="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-if="item.automatic" v-bind="attrs" v-on="on"
                >mdi-cog</v-icon
              >
            </template>
            <span>Automatisch</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import PersonItem from "common/components/PersonItem.vue";
export default defineComponent({
  name: "Tasks",
  components: { PersonItem },
  data() {
    return {
      loading: false,
      categories: [],
      selectedCategory: null,
      search: "",
      headers: [
        { text: "OK", value: "complete" },
        { text: "Person", value: "person" },
        { text: "Beschreibung", value: "description" },
        { text: "", value: "automatic" },
      ],
      items: [],
    };
  },
  computed: {
    filtered() {
      return this.items.filter(
        (item) =>
          (!this.selectedCategory ||
            this.selectedCategory === item.category.id) &&
          (!this.search ||
            item.description
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            (item.person &&
              item.person.firstName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.person &&
              item.person.lastName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.person &&
              item.person.code &&
              item.person.code
                .toLowerCase()
                .includes(this.search.toLowerCase())))
      );
    },
  },
  methods: {
    async complete(item) {
      item.complete = true;
      await this.apiPut({
        resource: "person/task",
        data: item,
      });
    },
  },
  async created() {
    this.loading = true;
    this.items = await this.apiList({ resource: "person/task" });
    this.categories = await this.apiList({ resource: "person/taskcategory" });
    this.loading = false;
  },
});
</script>
